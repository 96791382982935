// Fullcalendar
:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: var(--ion-color-light);
  --fc-neutral-text-color: #808080;
  --fc-border-color: transparent;

  --fc-event-bg-color: var(--ion-color-primary);
  --fc-event-border-color: trasparent;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(var(--ion-color-secondary-rgb), 0.2) !important;
  --fc-today-bg-color: white;

  --fc-now-indicator-color: red;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: none !important;
}

.fc-listWeek-view {
  .fc-list-day-cushion {
    background-color: white !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 6px solid rgba(0, 0, 0, 0.1);
  }

  .fc-event {
    background-color: white !important;
  }
}

.fc .fc-scrollgrid {
  border: 0 !important;
}

.fc .fc-timegrid-slot {
  height: 2.5em;

  &.fc-timegrid-slot-label {
    border: 0;
    opacity: 0.6;
    padding-right: 4px;
    bottom: 13px;
    position: relative;
    font-size: 14px;
  }
}

/* .fc .fc-timegrid-slots {
    tr:first-child {
      .fc-timegrid-slot {
        display: none;
      }
    }
  } */

.fc .fc-scrollgrid-section:first-child {
  td {
    border: 0;
  }
}

.fc .fc-timegrid-now-indicator-line {
  &::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: red;
    border-radius: 50%;
    top: -4px;
  }
}

.fc .fc-event {
  border: 0;
  background-color: var(--ion-color-secondary-light);
  .fc-event-time,
  .fc-event-title {
    font-weight: bold;
  }

  /* &.reserved {
    background-color: #e9e9e9;

    .fc-event-time,
    .fc-event-title {
      color: #424242;
    }
  }

  &.pending,
  &.waiting-payment {
    background-color: #fff3de;
    border: 0;

    .fc-event-time,
    .fc-event-title {
      color: #8b5a03;
    }
  }

  &.completed,
  &.confirmed {
    background-color: #e3efeb;
    border: 0;

    .fc-event-time,
    .fc-event-title {
      color: #1e5f47;
    }
  }

  &.canceled {
    background-color: #ffede6;

    .fc-event-time,
    .fc-event-title {
      color: #a60a00;
    }
  } */
}
