.ql-editor {
  padding: 0px !important;
  font-size: 15px !important;

  p {
    min-height: 14px;
  }

  ul {
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    li {
      list-style-type: disc;
      padding-left: 0;
    }
  }

  ol {
    padding-inline-start: 40px !important;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    li {
      list-style-type: decimal;
      padding-left: 0;
    }
  }
}
