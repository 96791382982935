/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
@import "@ionic/angular/css/palettes/dark.class.css";
/* @import "@ionic/angular/css/palettes/dark.system.css"; */

@import "./theme/ionic-overrides.scss";
@import "./theme/fullcalendar.scss";
@import "./theme/swiper.scss";
@import "./theme/quill.scss";

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

:root {
  /* --ion-color-primary: #7642ff;
  --ion-color-primary-rgb: 118, 66, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #683ae0;
  --ion-color-primary-tint: #8455ff; */

  --ion-color-primary: #1c1c1c;
  --ion-color-primary-rgb: 28, 28, 28;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #191919;
  --ion-color-primary-tint: #333333;

  --ion-color-secondary: #cfa95e;
  --ion-color-secondary-rgb: 207, 169, 94;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #b69553;
  --ion-color-secondary-tint: #d4b26e;
  --ion-color-secondary-light: #f3e9d7;
  --ion-color-secondary-light-rgb: 243, 233, 215;

  --ion-color-tertiary: #6bdaff;
  --ion-color-tertiary-rgb: 107, 218, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #5ec0e0;
  --ion-color-tertiary-tint: #7adeff;
  --ion-color-tertiary-light: #dff7ff;
  --ion-color-tertiary-light-rgb: 223, 247, 255;

  --ion-color-success: #52c41a;
  --ion-color-success-rgb: 82, 196, 26;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #48ac17;
  --ion-color-success-tint: #63ca31;

  --ion-color-warning: #faad14;
  --ion-color-warning-rgb: 250, 173, 20;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dc9812;
  --ion-color-warning-tint: #fbb52c;

  --ion-color-danger: #ff4d4f;
  --ion-color-danger-rgb: 255, 77, 79;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04446;
  --ion-color-danger-tint: #ff5f61;
  --ion-color-danger-light: #ffd3d4;
  --ion-color-danger-light-rgb: 255, 211, 212;

  --ion-color-light: #f0f0f0;
  --ion-color-light-rgb: 240, 240, 240;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d3d3d3;
  --ion-color-light-tint: #f2f2f2;

  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95, 95, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;

  --ion-font-family: "OpenSans", sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --ion-color-medium: #969696;
    --ion-color-medium-rgb: 150, 150, 150;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #848484;
    --ion-color-medium-tint: #a1a1a1;

    --ion-color-dark: #121212;
    --ion-color-dark-rgb: 18, 18, 18;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #101010;
    --ion-color-dark-tint: #2a2a2a;
  }
}

@media (max-width: 1024px) {
  .intercom-lightweight-app-launcher {
    bottom: 72px !important;
  }

  /*localhost*/
  .intercom-dfosxs {
    bottom: 72px !important;
  }

  iframe[name="intercom-launcher-frame"] {
    bottom: 60px !important;
    position: absolute !important;
  }

  @supports (padding-top: env(safe-area-inset-top)) and
    (padding-bottom: env(safe-area-inset-bottom)) {
    .intercom-lightweight-app-launcher {
      bottom: calc(72px + env(safe-area-inset-top)) !important;
    }

    /*localhost*/
    .intercom-dfosxs {
      bottom: calc(72px + env(safe-area-inset-top)) !important;
    }

    iframe[name="intercom-launcher-frame"] {
      bottom: calc(72px + env(safe-area-inset-top)) !important;
      position: absolute !important;
    }
    iframe[name="intercom-messenger-frame"] {
      height: calc(
        100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
      ) !important;
      margin-top: env(safe-area-inset-top) !important;
    }
  }
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

.responsive-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media (min-width: 1024px) {
  .responsive-grid {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 1024px) {
  .container {
    padding: 0 2rem;
  }
}

.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 768px) {
  .flex-container {
    flex-direction: row;
  }
}

.clickable {
  cursor: pointer;
  padding: 0.5rem;
}

@media (min-width: 1024px) {
  .clickable {
    padding: 0.75rem;
  }
}

ion-item {
  &::part(native) {
    border-color: rgba(0, 0, 0, 0.05);
  }
}

.content-wrapper {
  position: relative;
  background-color: white;
  z-index: 4;

  &::before {
    content: "";
    position: absolute;
    top: -23px;
    left: 0;
    right: 0;
    height: 24px;
    width: 100%;
    background-color: white;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-topright: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.no-ripple {
  --ripple-color: transparent;
}

ion-spinner {
  &[position="centered"] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[size="large"] {
    height: 60px;
    width: 60px;
  }
}

[transparent] {
  --background: transparent;
  background-color: transparent;
}

ion-item[input-container] {
  border: 2px solid var(--ion-color-light);
  border-radius: 10px;

  &.error {
    border-color: var(--ion-color-danger);
  }

  ion-input {
    margin: 0 0 12px 0;
  }
}
