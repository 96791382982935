swiper-container {
  swiper-slide {
    .play-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      font-size: 1.7rem;
      color: white;
    }

    media-player {
      width: 100%;
      height: 100%;

      media-poster {
        img {
          object-fit: cover !important;
        }
      }
    }
  }
  &::part(container) {
  }

  &::part(pagination) {
    /*     width: auto !important;
    height: auto !important;
    background: rgba(0, 0, 0, 0.5) !important;
    color: white !important;
    border-radius: 6px !important;
    font-size: 14px;
    padding: 2px 8px;
    display: flex;
    position: absolute;
    right: 15px;
    left: auto; */
  }

  &[pagination-type="fraction"] {
    &::part(pagination) {
      width: auto !important;
      height: auto !important;
      background: rgba(0, 0, 0, 0.6) !important;
      color: white !important;
      border-radius: 6px !important;
      gap: 2px;
      font-size: 11px !important;
      padding: 2px 8px !important;
      display: flex;
      position: absolute;
      right: 15px;
      left: auto;
      bottom: 35px;
      z-index: 99;
    }
  }
}
