@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

ion-button {
  --box-shadow: 0;
}

ion-thumbnail,
ion-card,
ion-button {
  --border-radius: 10px !important;
  border-radius: 10px !important;
}

ion-button {
  --border-radius: 10px !important;
  border-radius: 10px !important;
}

ion-footer {
  --background: white !important;
  ion-toolbar {
    --background: white !important;

    ion-button::part(native) {
      .button-inner {
        display: flex;
        justify-content: space-between !important;
      }
    }
  }
}

ion-searchbar {
  --background: var(--ion-color-secondary-light) !important;
}

ion-back-button::part(text) {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

ion-toolbar {
  --background: white;
  --border-width: 0 !important;

  &.toolbar-sticky {
    position: sticky;
    top: 0;
    z-index: 100;
    --background: transparent;

    ion-buttons {
      padding: 0 4px;
      transition: 0.3s;

      ion-back-button {
        --color: white;
        --background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(10px);
        font-size: 14px;

        &::part(native) {
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          --border-radius: 10px;
          -webkit-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.3);
          transition: 0.3s;

          ion-icon {
            margin: 0;
          }
        }
      }
      ion-button {
        --background: rgba(0, 0, 0, 0.6);
        --border-radius: 10px;
        backdrop-filter: blur(10px);
        -webkit-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        --color: white;
      }
    }

    &.scrolled {
      --background: white !important;

      ion-title {
        display: flex;
        opacity: 1;
        transition: opacity 0.3s;
        transition-delay: 0.2s;
      }

      ion-buttons {
        ion-back-button {
          --background: transparent;
          --color: black;
          &::part(native) {
            box-shadow: none !important;
          }
        }
        ion-button {
          width: auto;
          --color: black;
          --background: transparent;
          box-shadow: none !important;
        }
      }
    }
  }
}

ion-segment {
  --background: var(--ion-color-secondary-light);

  ion-segment-button {
    ion-label {
      align-items: center;
      display: flex;
      gap: 4px;
    }
  }
}

:host-context(.ion-palette-dark) {
  ion-toolbar {
    --background: black;
  }
}

ion-list {
  ion-list-header {
    ion-label {
      font-size: 14px;
      margin: 0 0 15px 0;
    }
  }
  ion-item {
    ion-avatar {
      width: 32px;
      height: 32px;
    }
  }
}

:host-context(.ion-palette-dark) {
  button {
    color: var(--ion-color-light) !important;
  }
}

/* ion-button:has(ion-spinner)::part(native) {
  padding-top: 7px;
  padding-bottom: 7px;
}

.md ion-back-button::part(text) {
  display: none;
}

.ios body.ion-palette-dark {
  ion-list.list-inset ion-item {
    --background: var(--ion-color-light);
  }
}

.ios body:not(.ion-palette-dark) {
  ion-datetime {
    --background: white;
  }
}
 */

ion-skeleton-text {
  &.button {
    border-radius: 30px;
    height: 40px;
    margin-top: 30px;
  }
}
